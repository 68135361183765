import { inject, Injectable } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { HeaderSearchService } from '../header-search/header-search.service';
// import { HeaderSearchService } from './header-search/header-search.service';

@Injectable({
  providedIn: 'root',
})
export class CommonTourService {
  readonly tourSteps: IStepOption[] = [
    {
      anchorId: 'multiple-dashboard',
      // content: 'translation.helptour.text22',  removed sincepdf changes
      content: 'Contrast Dashboard with simple and intutive user navigation ',
      route: 'cdm-metrics/my-dashboard',
      showProgress: true,
    },

    {
      anchorId: 'contrastdashboard',
      content: ' Contrast dashboard',
    },
    // {
    //   anchorId: 'radiology',
    //   content: 'translation.helptour.text23',
    //   route: 'cdm-metrics/my-dashboard',
    // },
    // for detailed view of hospital
    { anchorId: 'hospital', content: ' Select Hospital/Enterprise', route: '' },
    {
      anchorId: 'reportview',
      content: ' select  Report View -Modality',
      route: '',
    },
    {
      anchorId: 'datepicker',
      content:
        ' Select Month/Year- Analysis based on 12 month prior to selected date',
      route: '',
    },

    {
      anchorId: 'monthlyinjection',
      content:
        'Monthly Injections: arrow indicates trend over previous month, number shows injections in current month, and box shows absolute change over prior month. • Total is summary over past 12 month.',
      route: '',
    },
    {
      anchorId: 'contrastuse',
      content:
        'Contrast Use: same parameters as in number 4 Note: Approximate aggregated volume in mL',
      route: '',
    },
    {
      anchorId: 'syringeuse',
      content:
        'Syringe Use: same parameters as in number 4 • When CT-MP Report View is selected, Syringe Use is replaced by MP Set Use and Patient Line Use',
      route: '',
    },
    {
      anchorId: 'indicatorperformance',
      content:
        'Indicator Performance color code is based on an algorithm that analyzes KPI trend change from the previous month, the magnitude of the change, and its relative position to the Workflow Solutions //Insights community benchmark. Colorization can change significantly when trend change is ≥1 standard deviation ',
      route: '',
    },

    // for the summary repeat injectin
    {
      anchorId: 'summary',
      content:
        'Key Clinical Indicators to identify and track areas of potential workflow and process improvements',
      route: '',
    },
    {
      anchorId: 'repeatinjections',
      content:
        'A repeat injection is identified when multiple injections complete normally within one study. Preventable repeat injections may result in an increase in contrast delivered, additional scan time, and increased radiation to the patient',
      route: '',
    },
    {
      anchorId: 'ivissues',
      content:
        'An IV issue is identified when two injections are performed within one study of which the first was aborted. IV issues have a similar impact as repeat injections and can be targeted with catheter size and placement policies.',
      route: '',
    },
    {
      anchorId: 'losttime',
      content:
        'Time is lost when injections are repeated as described in the Repeat Injections and IV Issues key indicators. This lost time quantification may indicate a disruption in normal workflow efficiency.',
      route: '',
    },
    {
      anchorId: 'techabort',
      content:
        'Technologist Abort events are characterized by manual disarms. The events result in the premature termination of a programmed protocol and may indicate an interruption in normal workflow.',
      route: '',
    },
    {
      anchorId: 'flowratelimit',
      content:
        'Flow Rate Limiting events are characterized by a mechanical decrease in flow rate or automatic disarm when the chosen maximum pressure limit is reached. A decrease in flow rate during injection may lead to suboptimal image quality',
      route: '',
    },
    {
      anchorId: 'salinetestinject',
      content:
        'Saline Test Inject evaluates the patency of the IV pathway at the desired flow rate when delivered by the injection system. Studies have shown that this capability may reduce extravasations of contrast.',
      route: '',
    },
    {
      anchorId: 'unusedcontrast',
      content:
        'Unused Contrast is the approximate amount of contrast remaining in the syringe at the end of a single patient injection that must be appropriately discarded. In a multi-patient injection system, Unused Contrast is the approximate amount of contrast remaining in the device at the end of a delivery period.',
      route: '',
    },
    {
      anchorId: 'datacompleteness',
      content:
        'Data completeness represents the percentage of ancillary point of care data fields completed by the technologist on the injection system user interface.Regulatory compliance, reimbursement, and patient management may require documentation of contrast and injection parameters.',
      route: '',
    },

    // {
    //   anchorId: 'kpi-indicator',
    //   content: 'translation.helptour.text16',
    //   route: '',
    // },
    // {
    //   anchorId: 'benchmark-indicator',
    //   content: 'translation.helptour.text17',
    //   route: '',
    // },
    // {
    //   anchorId: 'observation-indicator',
    //   content: 'translation.helptour.text18',
    //   route: '',
    // },
    {
      anchorId: 'graph',
      content: 'translation.helptour.text20',
      route: '',
    },
    {
      anchorId: 'reports',
      content: 'translation.helptour.text33',
      route: 'report/standardreport',
    },
  ];

  public readonly tourService = inject(TourService);

  constructor(private headerservice: HeaderSearchService) {
    this.tourService.initialize(this.tourSteps, {
      enableBackdrop: true,
      backdropConfig: {
        offset: 10,
      },
      showProgress: true,
      disablePageScrolling: true,
    });
  }

  startTour() {
    this.headerservice.updateHospitalLocation(
      '121115,125372,4595511',
      '121115 - SALEM COMMUNITY HOSPITAL, 125372 - Salem Community Hospital, 4595511 - St Vincent Public Hospital',
      '/cdm-metrics/my-dashboard'
    );
    this.tourService.start();
  }
}
